<nav data-cy="navbar" class="nav">
  <div class="nav_first_part">
    <button (click)="toggleNavbar()" style="width: 12%;background: transparent;border: none; cursor: pointer;">
      <img src="assets/images/navbar/side_btn.svg" style="width: 100%;" alt="">
    </button>
    <span style="width: 130px;"><img src="assets/images/navbar/akooba_logo.svg" style="width: 100%;" alt=""></span>
    <div class="nav_first_shop">
      <p>{{boutique?.nom}}. <span>{{boutique?.localisation?.commune}} {{boutique?.localisation?.rue}}</span></p>
      <div class="nav_first_shop_hover">
        <div class="nav_shop_add" routerLink="/boutique" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">
          <i><img src="assets/images/navbar/add.png" alt=""></i>
          <span>Ajouter une nouvelle boutique</span>
        </div>
        <div class="nav_shop_forfait">
          <span>Choisir un forfait</span>
        </div>
        <div class="nav_shop_btn" *ngFor="let boutik of this.listBoutiques" (click)="swichBoutique(boutik)">
          <i><img src="assets/images/navbar/shop_img.png" alt=""></i>
          <p>{{boutik.nom}} <br>
          </p>
          <img src="assets/images/navbar/right_arrows.png" alt="right_arrows">
        </div>
      </div>
    </div>
  </div>
   <div class="nav_second_part">
    <div class="logout2__badge">
      <span (click)="copyMessage(this.codeBoutique)" value="cliquez pour copier" style="cursor: pointer;">{{this.codeBoutique}}</span>
    </div>
    <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menuu">
      <div class="circle-block">
          <span class="circle-indice">
            <span style="margin-right: 0.8px;">{{user.firstName | slice:0:1}}</span>
            <span>{{user.lastName | slice:0:1}}</span>
          </span>
      </div>
    </a>
    <nz-dropdown-menu #menuu="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item (click)="logout()">Se Déconneter</li>
        </ul>
    </nz-dropdown-menu>
  </div>
</nav>
<p-toast></p-toast>
