import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from '@core/auth/account.model';
import { AccountService } from '@core/auth/account.service';
import { EntityNavbarItems } from 'src/app/pages/entity-navbar-items';


@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  entitiesNavbarItems: any[] = [];
  account: Account | null = null;
  
  caisseIcon = '../../../../assets/images/navbar/cashregister.svg';
  clientIcon = '../../../../assets/images/navbar/client.svg';
  venteHistIcon = '../../../../assets/images/navbar/dash_icon.svg';
  comptaIcon = '../../../../assets/images/navbar/config_icon.svg';
  factureIcon = '../../../../assets/images/icons/newspaper.svg';
  boutiqueIco = '../../../../assets/images/admin/boutique.svg';
  
  categIcon = '../../../../assets/images/navbar/bxs-discount.svg';
  adminIcon = '../../../../assets/images/navbar/user_icon.svg';
  boutiqueIcon = '../../../../assets/images/navbar/cashregister.svg';
  historyImg = '../../../../assets/images/icons/ak-historic-vente.svg';
  entrepotImg = '../../../../assets/images/icons/entrepot.svg';
  economat = '../../../../assets/images/icons/economat.svg';


  isCollapsed = false;
  constructor(
    private router: Router,
    private accountService: AccountService,
  ) {

  }

  ngOnInit(): void {
    this.entitiesNavbarItems = EntityNavbarItems;

    this.accountService.getAuthenticationState().subscribe(account => {
      this.account = account;
    });
  }
}
