import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ApplicationConfigService } from '@core/config/application-config.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ErrorComponent } from './layouts/error/error.component';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import locale from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { fontAwesomeIcons } from './config/font-awesome-icons';
import { httpInterceptorProviders } from '@core/interceptor';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { fr_FR } from 'ng-zorro-antd/i18n';
import fr from '@angular/common/locales/fr';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { OverlayModule } from "@angular/cdk/overlay";
import { environment } from '@environments/environment';

registerLocaleData(fr);


@NgModule({
  imports: [
    NgxWebstorageModule.forRoot({ prefix: 'akooba', separator: '-', caseSensitive: true }),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    OverlayModule
  ],
  providers: [
    Title,
    { provide: LOCALE_ID, useValue: 'fr' },
    httpInterceptorProviders,
    MessageService,
    ConfirmationService,
    NzMessageService,
    NzModalService,
    { provide: NZ_I18N, useValue: fr_FR },
  ],
  declarations: [AppComponent,ErrorComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(applicationConfigService: ApplicationConfigService, iconLibrary: FaIconLibrary) {
  //  applicationConfigService.setEndpointPrefix("https://api.akooba.app");
   applicationConfigService.setEndpointPrefix(environment.contextPath);

    registerLocaleData(locale);
    iconLibrary.addIcons(...fontAwesomeIcons);
  }

 }

