export const CONSTANTS = {
  home: {
    homePageTitle: 'Accueil'
  },
  errorPage: {
    defaultTitle: 'Ooops..',
    notFound: 'Page Introuvable',
    errorDescription: 'Oopps !! The page you are looking for does not exist.',
    wrongErrorTitle: 'Something went wrong',
    wrongErrorDescription: `Looks like something went wrong.<br> We're working on it`
  },
  auth: {
    loginSuccessMsg: 'Vous etes à present connecté',
    logoutSuccessMsg: 'Vous etes à present deconnecté',
    loginErrorMsg:"Connexion",
    loginConnexion:"Connexion en cour",
    loginError:"Erreur, connexion impossible"
  },
  authPermissions: {
   //*******************************OTHER-PERMISSIONS******************************************/
    requete: ['REQUETE'],
    admin: ['ADMINISTRATEUR'],

   /************************************************************************/
  },
  toatsMessage:{
     create: 'Création avec succès',
     update: 'Mise à jour avec succès',
     delete: 'Suppression avec succès',
     status:  'Status mise à jour avec succès',
     confirmText: 'Vous êtes sûr de vouloir supprimer' + ' ',
     header: "Confirmation"
  },
  loadinMessage: {
    get: 'Chargement en cours...',
    create: 'Création en cours...',
    update: 'Modification en cours...',
    delete: 'Suppression en cours...',
  }

};
