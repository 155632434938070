import { LocalStorageService } from 'ngx-webstorage';
import { ApplicationConfigService } from '@core/config/application-config.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders,HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import {Router} from '@angular/router';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class BoutiqueService {
  protected resourceUrl = this.applicationConfigService.getEndpointFor('/boutiques');
  codeBoutique :string = this.localStorageService.retrieve('idBoutique');
  headers = new HttpHeaders();
  httpOptions: any;
  httpOptionsUpload: any;
  httpOptionsLogin: any;
  httOptionJson: any;
  httpOptionsUploadJson: any;
  loading: boolean ;
  httOptionToken: any ;

  testLogin: boolean = false

  constructor(
    private http: HttpClient,
    private route: Router,
    private messageService: MessageService,
    protected applicationConfigService: ApplicationConfigService,
    private localStorageService: LocalStorageService
    ) {
     this.httpOptionsLogin = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.httOptionJson = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorageService.retrieve('authenticationToken')}`,
       'tenant-id': localStorageService.retrieve('tenantId'),
      })
    };
    this.httpOptionsUpload = {
      headers: new HttpHeaders({
      // 'Content-Type': 'html/text',
         Authorization: `Bearer ${localStorageService.retrieve('authenticationToken')}`,
         'tenant-id': localStorageService.retrieve('tenantId'),
      })
    };
  }
  get(): Observable<any> {
    return new Observable((observer) => {
      this.http.get(this.resourceUrl,this.httOptionJson).subscribe(
        result => {
          observer.next(result);
        },
        (error: HttpErrorResponse) => {
          if (error.status === 401){
            this.route.navigate(['/login']);
          }else {
            error;
          }
        },() => {
          observer.complete();
        }
      );
    });
  }
  getPack(): Observable<any> {
    return new Observable((observer) => {
      this.http.get(this.applicationConfigService.getEndpointFor('/packs'),this.httOptionJson).subscribe(
        result => {
          observer.next(result);
        },
        (error: HttpErrorResponse) => {
          if (error.status === 401){
            this.route.navigate(['/login']);
          }else {
            error;
          }
        },() => {
          observer.complete();
        }
      );
    });
  }
  post(data: any): Observable<any> {
    return new Observable((observer) => {
      this.http.post(this.resourceUrl, data,this.httOptionJson).subscribe(
        result => {
          observer.next(result);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          if (error.status === 401 && error.statusText !== "Unauthorized"){
            this.route.navigate(["/login"]);
          }else {

            error
          }
        },()=>{
          observer.complete()
        }
      )
    });
  }
  putSimple(id:string,boutique:any, data:any): Observable<any> {
    return new Observable((observer) => {
      this.http.put(this.resourceUrl+'/'+id + '?boutique=' + boutique,data,this.httOptionJson).subscribe(
        result => {
          observer.next(result);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          if(error.status === 401 && error.statusText !== "Unauthorized"){
            this.route.navigate(["/login"]);
          }else {
            error
          }

        },()=>{
          observer.complete();
        }
      );
    });
  }
  postUpload(data: any): Observable<any> {
    return new Observable((observer) => {
      this.http.post(this.resourceUrl+"/images",data,this.httpOptionsUpload).subscribe(
        result => {
          observer.next(result);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          if (error.status === 401 && error.statusText !== "Unauthorized"){
            this.route.navigate(["/login"]);
          }else {
            error
          }
        },()=>{
          observer.complete()
        }
      )
    })
  }
  delete(data: any, boutique:any){
    return new Observable((observer) => {
      this.http.delete(this.resourceUrl+'/' +data + '?boutique=' + boutique ,this.httOptionJson).subscribe(
        result => {
          observer.next(result);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          if (error.status === 401 && error.statusText !== 'Unauthorized'){
            this.route.navigate(['/login']);
          }else {
            error
          }
        },() => {
          observer.complete();
        }
      );
    });
  }

  getBoutiqueById(id:string): Observable<any> {
    return new Observable((observer) => {
      this.http.get(this.resourceUrl+'/'+id,this.httOptionJson).subscribe(
        result => {
          observer.next(result);
        },
        (error: HttpErrorResponse) => {
          if (error.status === 401){
            this.route.navigate(['/login']);
          }else {
            error
          }
        },() => {
          observer.complete();
        }
      );
    });
  }
}


