import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserRouteAccessService } from '@core/auth/user-route-access.service';

import { errorRoute } from './layouts/error/error.route';
import { navbarRoute } from './layouts/navbar/navbar.route';
import { sidebarRoute } from './layouts/sidebar/sidebar.route';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () => import(`./pages/page.module`).then(m => m.PageModule),
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'session',
          data: { pageTitle: 'Session' },
          loadChildren: () => import('./session/sessions.module').then(m => m.SessionModule),
        },
        {
          path: 'login',
          loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        },
        navbarRoute,
        sidebarRoute,
        ...errorRoute,
      ]
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
