import { Account } from '@core/auth/account.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { MessageService } from 'primeng/api';
import { BoutiqueService } from './../../pages/config/boutique/services/boutique.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login/login.service';
import { CONSTANTS } from '@core/constants/constants';


import { EntityNavbarItems } from 'src/app/pages/entity-navbar-items';
import { Boutique } from 'src/app/pages/models/boutique';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  inProduction?: boolean;
  isNavbarCollapsed = true;
  entitiesNavbarItems: any[] = [];
  boutique:any;
  listBoutiques:Boutique[] = [];
  listPack:any;
  loading:boolean;
  codeBoutique:string;
  user:Account;
  @Output() toggleEvent = new EventEmitter();
  idBoutique :string |null =this.localStorageService.retrieve('idBoutique');

  constructor(
    private loginService: LoginService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private boutiqueService:BoutiqueService,
    private messageService: MessageService,
    private message: NzMessageService,
    private modal: NzModalService,
  ) {
  }


  ngOnInit(): void {
    this.entitiesNavbarItems = EntityNavbarItems;
    this.getAll();
    this.getBoutique();
    this.getPack();
    this.codeBoutique = this.localStorageService.retrieve('tenantId');
    this.user = this.localStorageService.retrieve('user');
    this.idBoutique  = this.localStorageService.retrieve('idBoutique');
    setTimeout(()=>{
      console.log('test',this.listBoutiques);
      console.log("LUSUER", this.user);
      
    },1000)

  }

  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
  }

  login(): void {
    this.router.navigate(['/login']);
  }

  getBoutique(){
    this.boutiqueService.getBoutiqueById(this.idBoutique)
    .subscribe(
      response => {
        this.boutique = response;
        console.log("NUODEM ", this.boutique);
        
        this.localStorageService.store('factureBoutique',this.boutique);

      }, error => {
        console.log(error);

        this.messageService.add({severity:"error",detail:error});
      }
    );
  }

  getAll() {
    this.loading = true;
     const id = this.message.loading(CONSTANTS.loadinMessage.get, { nzDuration: 0 }).messageId;
     this.boutiqueService
     .get()
     .subscribe(
       response => {
         this.listBoutiques = response;
         console.log("oury x",this.listBoutiques)
        localStorage.setItem("boutique",(JSON.stringify(this.listBoutiques[0]['_id'])));
        localStorage.setItem("user",(JSON.stringify(this.listBoutiques[0])));
        localStorage.setItem("listBoutiques",(JSON.stringify(this.listBoutiques)));

         this.message.remove(id);
         this.loading = false;
       },
       error => {
        this.message.remove(id);
         this.messageService.add({severity: 'error', summary: 'Error', detail: error});
       }
     );
  }

  swichBoutique(item: any) {
    this.modal.confirm({
      nzTitle: 'Voulez changer de boutique?',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.localStorageService.store('idBoutique',item._id);
        this.getBoutique();
        // localStorage.setItem("boutique",(JSON.stringify(item._id)));
        window.location.reload();
      },
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });
  }


copyMessage(val: string){
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = val;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  this.messageService.add({severity: 'success', detail: 'Code boutique copié avec succès'});

}

  toggleNavbar(): void {
    this.toggleEvent.emit();
  }

  handleCloseSession() {
    const id = this.message.loading('Cloture en cour...', {
      nzDuration: 0,
    }).messageId;
    this.localDatas;
  }

  localDatas() {
    this.localStorageService.clear('tenantId');
    this.localStorageService.clear('user');
    this.localStorageService.clear('idUser');
    this.localStorageService.clear('idBoutique');
    // this.localStorageService.clear('session');
    localStorage.removeItem('session');
    this.router.navigate(['/login']);
  }

  logout() {
    this.modal.confirm({
      nzTitle: 'voulez-vous vous deconnecter ?',
      nzOkText: 'Oui',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.loginService.logout();
      },
      nzCancelText: 'Non',
      nzOnCancel: () => console.log('Cancel'),
    });
  }
  logoutSession() {
    this.modal.confirm({
      nzTitle: 'voulez-vous cloturer la session ?',
      nzOkText: 'Oui',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.handleCloseSession();
      },
      nzCancelText: 'Non',
      nzOnCancel: () => console.log('Cancel'),
    });
  }

  getPack(){
    this.boutiqueService
    .getPack()
    .subscribe(
      response => {
        this.listPack = response;
     }, error => {
       console.log(error);
       this.messageService.add({severity:"error",detail:error});
     }
   );
  }

}
