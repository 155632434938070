<div class="nav_sidebar">
  <div class="nav_sidebar_head" >
    <img src="assets/images/navbar/akooba-logo.png" style="width: 100%;"  alt="">
  </div>

  <ul nz-menu nzMode="inline" class="sider-menu" [nzInlineCollapsed]="isCollapsed" [style]="{height: '100%'}">
    <ul nz-menu class="menu-container">
      


            <li nz-submenu nzTitle="Tableau  Général " nzIcon="dashboard" [ngClass]="['navItem']" [style]="{color: '#171D32'}"
                    
      >
          <ul>
              <li nz-menu-item nzMatchRouter [ngClass]="['navItem']" routerLink="/dashboard" routerLinkActive="active-link"
              >
                  <i nz-icon nzType="dashboard" nzTheme="outline"></i>
                  <span [ngClass]="['navLink']">Tableau  Général </span>
              </li>
              <li  nz-menu-item nzMatchRouter  routerLink="/historique-vente" routerLinkActive="active-link" [ngClass]="['navItem']">
                
                <i nz-icon>
                  <img [src]="historyImg" alt="" [ngClass]="['navIcon']">
                </i>
                <span>Historiques Ventes</span>
            </li>
          </ul>
      </li>

            <!-- <li  nz-menu-item nzMatchRouter routerLink="/tresorerie" [ngClass]="['navItem']">
              <i nz-icon>
                 <img [src]="caisseIcon" alt="" [ngClass]="['navIcon']">
               </i>
              <span>Caisse</span>
          </li> -->

          <li  nz-menu-item nzMatchRouter  routerLink="/client" routerLinkActive="active-link" [ngClass]="['navItem']">
            <i nz-icon>
               <img [src]="clientIcon" alt="" [ngClass]="['navIcon']">
             </i>
            <span>Clients</span>
        </li>

        <li  nz-menu-item nzMatchRouter  routerLink="/entrepot" routerLinkActive="active-link" [ngClass]="['navItem']">
          <i nz-icon>
             <img [src]="entrepotImg" alt="" [ngClass]="['navIcon']">
           </i>
          <span>Gestion stock</span>
      </li>
        

      <li nz-submenu nzTitle="Comptabilité" nzIcon="gold" [ngClass]="['navItem']" [style]="{color: '#171D32'}"
                    
      >
          <ul>
              <li nz-menu-item nzMatchRouter [ngClass]="['navItem']" routerLink="/tresorerie" routerLinkActive="active-link"
              >
                  
                  <i nz-icon>
                    <img [src]="economat" alt="" [ngClass]="['navIcon']">
                  </i>
                  <span [ngClass]="['navLink']">Trésorerie</span>
              </li>
              <li  nz-menu-item nzMatchRouter routerLink="/factures" routerLinkActive="active-link" [ngClass]="['navItem']">
                  <i nz-icon>
                     <img [src]="factureIcon" alt="" [ngClass]="['navIcon']">
                   </i>
                  <span>Facture</span>
              </li>
          </ul>
      </li>


      <li nz-submenu nzTitle="Configuration" nzIcon="pie-chart" [ngClass]="['navItem']" [style]="{color: '#171D32'}"
                    
      >
          <ul>
              
              <li  nz-menu-item nzMatchRouter routerLink="/liste-produit" routerLinkActive="active-link" [ngClass]="['navItem']">
                  <i nz-icon>
                     <img [src]="entrepotImg" alt="" [ngClass]="['navIcon']">
                   </i>
                  <span>Produits</span>
              </li>
              <li  nz-menu-item nzMatchRouter routerLink="/boutique" routerLinkActive="active-link" [ngClass]="['navItem']">
                <i nz-icon>
                   <img [src]="boutiqueIcon" alt="" [ngClass]="['navIcon']">
                 </i>
                <span>Boutique</span>
            </li>

            <li  nz-menu-item nzMatchRouter routerLink="/administrateurs" routerLinkActive="active-link" [ngClass]="['navItem']">
              <i nz-icon>
                 <img [src]="adminIcon" alt="" [ngClass]="['navIcon']">
               </i>
              <span>Administrateurs</span>
          </li>
          <li  nz-menu-item nzMatchRouter routerLink="/categories" routerLinkActive="active-link" [ngClass]="['navItem']">
            <i nz-icon>
               <img [src]="categIcon" alt="" [ngClass]="['navIcon']">
             </i>
            <span>Categorie</span>
        </li>
          </ul>
      </li>


    </ul>
  </ul>


  <div class="nav_sidebar_footer">
    <a [routerLink]="">
      <img src="assets/images/navbar/md-log-out.svg" alt="">
      <span>Cloturer la section</span>
    </a>
    <img src="assets/images/navbar/side_footer_icon.png"  class="nav_footer_img" alt="">
  </div> 
</div>
